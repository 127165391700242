<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('templateNotification.info')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout
              wrap
              layout>
              <v-flex
                xs12
                sm6
                md4>
                <v-text-field
                  :rules="[requiredValue]"
                  v-model="templateName"
                  :label="$t('templateNotification.name')"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
                style="display: flex;">
                <v-autocomplete
                  id="templateFieldValue"
                  v-model="templateEmailField"
                  :items="templateNotificationFields"
                  :clearable="true"
                  :label="$t('templateNotification.chooseValue')"
                  type="text"
                  class="required"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-autocomplete>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-clipboard:copy="templateEmailField"
                      v-clipboard:success="onCopy"
                      class="ml-2 add-text"
                      style="cursor: pointer;"
                      color="success"
                      v-on="on"
                    >mdi-message-plus</v-icon
                    >
                  </template>
                  <span>{{ $t("common.insert") }}</span>
                </v-tooltip>
              </v-flex>
              <v-flex
                xs12
                sm6
                md4
                style="display: flex;">
                <v-autocomplete
                  id="templateFieldValue"
                  v-model="templateEmailDefaultValue"
                  :items="templateEmailDefaults"
                  :clearable="true"
                  :label="$t('templateNotification.chooseTemplateDefault')"
                  type="text"
                  class="required"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-autocomplete>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="ml-2"
                      color="success"
                      v-on="on"
                      @click="onPreviewTemplate(templateEmailDefaultValue)"
                    >mdi-file-find</v-icon
                    >
                  </template>
                  <span>{{ $t("templateNotification.previewTemplate") }}</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-form>
        </material-card>
        <material-card
          :title="$t('sideBar.configTemplateNotification')"
          color="green"
          flat
          full-width
        >
          <v-flex
            xs12
            sm12
            md12
            mb-3
            class="bg-white"
          >
            <v-text-field
              :rules="[requiredValue]"
              v-model="templateTitle"
              :label="$t('templateNotification.templateTitle')"
              class="required"
            />
          </v-flex>
          <ckeditor
            :editor="editor"
            v-model="editorData"
            :config="editorConfig"
            @ready="onEditorReady"
          />
        </material-card>
        <v-btn
          class="white--text"
          color="red"
          style="margin-left: 0; margin-right: 5px;"
          @click="onClose()"
        >
          <span class="btn-label">
            <i
              class="glyphicon glyphicon-remove"
              style="margin-right: 5px;" />
          </span>
          {{ $t("common.close") }}
        </v-btn>
        <v-btn
          color="primary"
          style="color: #ffffff; margin: 0;"
          @click="onSaveTemplate"
        >
          <v-icon class="mr-2">mdi-content-save-move-outline</v-icon>
          <span style="text-transform: none;">{{ $t("common.save") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <previewTemplateDefaultModal
      ref="previewTemplateDefault"
      @onConfirm="onUsedThisTemplate"
    />
  </v-container>
</template>

<script>
import '@ckeditor/ckeditor5-build-classic/build/translations/vi'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert'
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter'
import { mapActions, mapGetters } from 'vuex'
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed'
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import PreviewTemplateDefaultModal from './PreviewTemplateDefault'
export default {
  components: {
    PreviewTemplateDefaultModal
  },
  data () {
    return {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        // The configuration of the editor.
        plugins: [
          Table,
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          EasyImage,
          Heading,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          TableToolbar,
          TableProperties,
          TableCellProperties,
          Alignment,
          ListStyle,
          ImageInsert,
          Base64UploadAdapter,
          SimpleUploadAdapter,
          HtmlEmbed
        ],
        toolbar: {
          items: [
            // 'heading',
            // '|',
            // 'alignment',
            // 'bold',
            // 'italic',
            // '|',
            // 'link',
            // 'insertTable',
            // '|',
            // 'bulletedList',
            // 'numberedList',
            // '|',
            // 'imageInsert',
            // 'blockQuote',
            // '|',
            'undo',
            'redo'
            // 'htmlEmbed'
          ]
        },
        image: {
          toolbar: [
            'imageStyle:full',
            'imageStyle:side',
            '|',
            'imageTextAlternative'
          ]
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableProperties',
            'tableCellProperties',
            'insertTableRowAbove'
          ]
        },
        language: 'vi'
        // htmlEmbed: {
        //   showPreviews: true,
        //   sanitizeHtml: (inputHtml) => {
        //     // Strip unsafe elements and attributes, e.g.:
        //     // the `<script>` elements and `on*` attributes.
        //     const outputHtml = sanitize(inputHtml)
        //     return {
        //       html: outputHtml,
        //       // true or false depending on whether the sanitizer stripped anything.
        //       hasChanged: true
        //     }
        //   }
        // }
        // Upload image
        // simpleUpload: {
        //   // The URL that the images are uploaded to.
        //   uploadUrl: 'http://127.0.0.1:8000/api/v1/evoucher/',
        //   // Headers sent along with the XMLHttpRequest to the upload server.
        //   headers: {
        //     Authorization: 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjozLCJ1c2VybmFtZSI6InllbmN1bmdjYXAiLCJleHAiOjE2MTA1MDU1MzQsImVtYWlsIjoieWVucG4uYWxpQGdtYWlsLmNvbSIsIm9yaWdfaWF0IjoxNjA5OTAwNzM0fQ.yxz_V1zwfB7JfVaJVVH43I5sZM7NqPUqajmglj_4L_Q'
        //   }
        // }
      },
      templateTitle: '',
      templateName: null,
      templateNotificationFields: [],
      templateEmailField: null,
      copiedText: null,
      templateEmailDefaults: [],
      templateEmailDefaultValue: null,
      valid: false
    }
  },
  computed: {
    ...mapGetters(['GET_SNACK_BAR'])
  },
  created () {
    let templateId = this.$route.params.templateId
    if (!functionUtils.isNull(templateId)) {
      this.GET_TEMPLATE_NOTIFICATION_DETAIL({ id: templateId }).then(
        function (res) {
          let data = res.data
          this.editorData = data.content
          this.templateName = data.name
          this.templateTitle = data.title
        }.bind(this)
      )
    }
    let categoryId = this.$route.params.categoryId
    if (!functionUtils.isNull(categoryId)) {
      let filter = {
        params: {
          categoryId: categoryId
        }
      }
      this.GET_FIELDS_TEMPLATE_NOTIFICATION_BY_CATEGORY(filter).then(
        function (res) {
          let data = res.data
          this.templateNotificationFields = []
          for (let i = 0, size = data.length; i < size; i++) {
            let obj = {
              value: data[i].value,
              text: data[i].name
            }
            this.templateNotificationFields.push(obj)
          }
        }.bind(this)
      )
    }
    this.getTemplateEmailDefault()
  },
  methods: {
    // Required value
    requiredValue (value) {
      if (!functionUtils.isNull(value) && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    onCopy: function (e) {
      this.copiedText = e.text
      this.ON_SHOW_TOAST({
        message: this.$t('common.success'),
        styleType: ToastType.SUCCESS
      })
    },
    onEditorReady (editor) {
      document.querySelector('.add-text').addEventListener('click', () => {
        this.addTag(editor)
      })
    },
    addTag (editor) {
      editor.model.change((writer) => {
        const insertPosition = editor.model.document.selection.getFirstPosition()
        writer.insertText(this.copiedText, insertPosition)
      })
    },
    onError: function (e) {
      alert('Failed to copy texts')
    },
    /**
     * Used this template
     */
    onUsedThisTemplate: function (htmlContent) {
      this.editorData = htmlContent
    },
    /**
     * Preview template
     */
    onPreviewTemplate: function (value) {
      this.$refs.previewTemplateDefault.onShowModal(value)
    },
    /**
     * Get template email default
     */
    getTemplateEmailDefault: function () {
      let categoryId = this.$route.params.categoryId
      let filter = {
        params: {
          categoryId: categoryId
        }
      }
      this.GET_TEMPLATE_NOTIFICATION_DEFAULT_BY_CATEGORY(filter).then(
        function (res) {
          let data = res.data
          this.templateEmailDefaults = []
          for (let i = 0, size = data.length; i < size; i++) {
            let obj = {
              text: data[i].name,
              value: data[i].value
            }
            this.templateEmailDefaults.push(obj)
          }
        }.bind(this)
      )
    },
    /**
     * Copy field value
     */
    // copyFieldValue: function () {
    //   let templateFieldValue = document.getElementById('templateFieldValue')
    //   templateFieldValue.select()
    //   try {
    //     var successful = document.execCommand('copy')
    //     var msg = successful ? 'successful' : 'unsuccessful'
    //     alert('Testing code was copied ' + msg)
    //   } catch (err) {
    //     alert('Oops, unable to copy')
    //   }
    // },
    /**
     * Go back
     */
    onClose: function () {
      window.history.back()
    },
    /**
     * Save template
     */
    onSaveTemplate: function () {
      if (this.$refs.form.validate()) {
        let templateId = this.$route.params.templateId
        if (functionUtils.validateStringIsNull(this.templateTitle)) {
          this.ON_SHOW_TOAST({
            message: this.$t('templateNotification.templateTitle'),
            styleType: ToastType.ERROR
          })
        } else if (functionUtils.validateStringIsNull(this.editorData)) {
          this.ON_SHOW_TOAST({
            message: this.$t('templateNotification.templateContent'),
            styleType: ToastType.ERROR
          })
        } else if (functionUtils.isNull(templateId)) {
          let categoryId = this.$route.params.categoryId
          let filter = {
            name: this.templateName,
            categoryId: categoryId,
            title: this.templateTitle,
            content: this.editorData
          }
          this.CREATE_TEMPLATE_NOTIFICATION(filter)
            .then(
              function () {
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
              }.bind(this)
            )
            .catch(
              function () {
                this.ON_SHOW_TOAST({
                  message: this.$t('login.somethingWentWrong'),
                  styleType: ToastType.ERROR
                })
              }.bind(this)
            )
        } else {
          let filter = {
            title: this.templateTitle,
            content: this.editorData,
            name: this.templateName
          }
          this.UPDATE_TEMPLATE_NOTIFICATION({ id: templateId, ...filter })
            .then(
              function () {
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
              }.bind(this)
            )
            .catch(
              function () {
                this.ON_SHOW_TOAST({
                  message: this.$t('login.somethingWentWrong'),
                  styleType: ToastType.ERROR
                })
              }.bind(this)
            )
        }
      }
    },
    onReady (editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        )
    },
    ...mapActions([
      'CREATE_TEMPLATE_NOTIFICATION',
      'UPDATE_TEMPLATE_NOTIFICATION',
      'ON_SHOW_TOAST',
      'GET_TEMPLATE_NOTIFICATION_DETAIL',
      'GET_FIELDS_TEMPLATE_NOTIFICATION_BY_CATEGORY',
      'GET_TEMPLATE_NOTIFICATION_DEFAULT_BY_CATEGORY'
    ])
  }
}
</script>

<style lang="scss" scoped>
.bg-white {
  background-color: #fff !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
