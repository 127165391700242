import { render, staticRenderFns } from "./ProjectTemplateNotification.vue?vue&type=template&id=25fb726b&scoped=true&"
import script from "./ProjectTemplateNotification.vue?vue&type=script&lang=js&"
export * from "./ProjectTemplateNotification.vue?vue&type=script&lang=js&"
import style0 from "./ProjectTemplateNotification.vue?vue&type=style&index=0&id=25fb726b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25fb726b",
  null
  
)

export default component.exports